<template>
  <div class="GroupDetail">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <el-button
            style="height: 40px; margin-right: 10px"
            type="primary"
            plain
            @click="handleAdd"
            >新增用户</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="600"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="序号" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登录账号" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.account }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户名称" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户手机号码" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户邮箱" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.email }}</span>
            </template>
          </el-table-column>
          <el-table-column label=" 创建时间" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row.id)"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="editPassword(scope.$index, scope.row)"
                >修改密码</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormEditVisibleCode"
      width="40%"
      class="group"
      :close-on-click-modal="false"
      @close="dialogCloseTwo"
      :destroy-on-close="true"
    >
      <el-form
        ref="codeForm"
        :model="codeForm"
        :label-position="labelPosition"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="旧密码:" prop="oldPassword">
          <el-input v-model="codeForm.oldPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPassword">
          <el-input v-model="codeForm.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="newPasswordTwo">
          <el-input v-model="codeForm.newPasswordTwo" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushPassword">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑用户"
      :visible.sync="dialogFormEditVisible"
      width="30%"
      class="group"
      :close-on-click-modal="false"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        :label-position="labelPosition"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="登录账号:" prop="account">
          <el-input v-model="editForm.account"></el-input>
        </el-form-item>
        <el-form-item label="用户名称:">
          <el-input v-model="editForm.username"></el-input>
        </el-form-item>
        <el-form-item label="用户手机号:" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="用户邮箱:" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushEditGroup">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="新增用户"
      :visible.sync="dialogFormAddVisible"
      width="40%"
      @close="dialogClose"
      :close-on-click-modal="false"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        :label-position="labelPosition"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="登录账号:" prop="account">
          <el-input v-model="addForm.account"></el-input>
        </el-form-item>
        <el-form-item label="用户名称:">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="用户密码:" prop="password">
          <el-input v-model="addForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="用户手机号:" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="用户邮箱:" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushGroup">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.codeForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userId: "",
      email: "",
      phone: "",
      username: "",
      account: "",
      passwordId: "",
      oldPassword: "",
      newPassword: "",
      totalCount: 0,
      isUp: false,
      competition: this.$route.params.id,
      id: this.$route.params.id,
      groupCode: "",
      name: "",
      groupCodeName: "",
      fileList: [],
      labelPosition: "right",
      value: "所有",
      value2: true,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogFormEditVisible: false,
      dialogFormAddVisible: false,
      dialogFormEditVisibleCode: false,
      codeName: "",
      editForm: {},
      codeForm: {
        newPasswordTwo: "",
      },
      addForm: {},
      password: "",
      newPassword: "",
      // newPasswordTwo: "",
      rules: {
        account: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入用户密码", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧的密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        newPasswordTwo: [
          // { required: true, message: "请输入确认密码", trigger: "blur" },
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTabeleData();
  },
  inject: ["reload"],
  methods: {
    //关闭新增组别弹框后进行数据清空操作
    dialogClose() {
      this.addForm = {};
    },
    dialogCloseTwo() {
      this.codeForm = {};
    },
    //删除用户
    handleDelete(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.removeUser(`id=${id}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getTabeleData();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });
    },
    //修改密码
    editPassword(index, row) {
      this.passwordId = row.id;
      this.dialogFormEditVisibleCode = true;
    },
    //编辑前对编辑内容的处理
    handleEdit(index, row) {
      this.userId = row.id;
      // this.phone = row.phone;
      // this.email = row.email;
      this.editForm = JSON.parse(JSON.stringify(row));
      this.dialogFormEditVisible = true;
    },
    //新增弹框按钮
    handleAdd() {
      this.dialogFormAddVisible = true;
    },
    //获取列表
    async getTabeleData() {
      try {
        const result = await this.$api.userQuery(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        this.tableData = result.pageResults;
        console.log(result);
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //每页显示条数改变
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTabeleData();
    },
    //当前页跳转
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTabeleData();
    },
    //新增用户
    pushGroup() {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          this.dialogFormAddVisible = false;
          console.log(this.addForm);
          try {
            const result = await this.$api.userAdd(this.addForm);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.getTabeleData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改密码
    pushPassword() {
      // if (!this.codeForm.newPasswordTwo) {
      //   this.$message({
      //     type: "warning",
      //     message: "请输入确认密码",
      //   });
      // } else if (this.codeForm.newPassword != this.codeForm.newPasswordTwo) {
      //   this.$message({
      //     type: "warning",
      //     message: "两次新密码不一致",
      //   });
      // } else {
      this.$refs.codeForm.validate(async (valid) => {
        if (valid) {
          try {
            const result = await this.$api.changePassword(
              `id=${this.passwordId}&oldPassword=${this.codeForm.oldPassword}&newPassword=${this.codeForm.newPassword}`
            );
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.dialogFormEditVisibleCode = false;
              this.getTabeleData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
              //  this.dialogFormEditVisibleCode = true;
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // }
    },
    //提交编辑
    pushEditGroup() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.dialogFormEditVisible = false;
          try {
            const result = await this.$api.userUpdate(
              `id=${this.userId}&account=${this.editForm.account}&phone=${
                this.editForm.phone ? this.editForm.phone : ""
              }&username=${
                this.editForm.username ? this.editForm.username : ""
              }&email=${this.editForm.email ? this.editForm.email : ""}`
            );
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.getTabeleData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.GroupDetail {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
  .national {
    width: 65px;
  }
  .select {
    .el-input {
      width: 93%;
    }
  }
}
</style>
